// Anything exported from this file is importable by other in-browser modules.
import { AxiosResponse } from "axios";
import { HttpService } from "./http.service";

const service = new HttpService();

export function Put<T, T2 = T>(
  url: string,
  data: T,
  baseUrlOverwrite?: string
): Promise<T2> {
  return service.Put(url, data, baseUrlOverwrite);
}

export function Post<T>(
  url: string,
  data: T,
  baseUrlOverwrite?: string
): Promise<T> {
  return service.Post(url, data, baseUrlOverwrite);
}

export function Patch<T>(
  url: string,
  data: T,
  baseUrlOverwrite?: string
): Promise<T> {
  return service.Patch(url, data, baseUrlOverwrite);
}

export function Get<T>(url: string, baseUrlOverwrite?: string): Promise<T> {
  return service.Get(url, baseUrlOverwrite);
}

export function GetAxios<T>(
  url: string,
  baseUrlOverwrite?: string,
  options?: any
): Promise<AxiosResponse<T>> {
  return service.GetAxios(url, baseUrlOverwrite, options);
}

export function Delete<T>(url: string, baseUrlOverwrite?: string): Promise<T> {
  return service.Delete(url, baseUrlOverwrite);
}

export const isLoading$ = service.isLoading$;
