/* eslint-disable prettier/prettier */
// import { axios } from '../base/baseAxios';
import axios, { AxiosResponse } from "axios";
import endpoint from '../environment.config';

import {
  getSilentToken,
} from "@gz/auth";
import { BehaviorSubject } from 'rxjs';
import axiosRetry from 'axios-retry';

import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"


const instance = axios.create({
	baseURL: endpoint.BaseUrl,
});

axiosRetry(instance, {
	retries: 5,
	retryDelay: retryCount => {
		return retryCount * 500;
	},
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.common.Authorization =
      "Bearer " +
      JSON.parse(sessionStorage.getItem("AuthDetails") || "").accessToken;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
	(response: any) => {
		return response;
	},
	async (error: any) => {
		const { config, response } = error;
		const originalRequest = config;
		
		if (response?.data?.error) {
			Toastify({
				text: response?.data?.error,
				duration: 3000,
				close: true,
				gravity: "top", // `top` or `bottom`
				position: "right", // `left`, `center` or `right`
				stopOnFocus: true, // Prevents dismissing of toast on hover
				style: {
					background: "linear-gradient(to right, var(--v-error-base), var(--v-error-darken1))",
				},
				onClick: function(){} // Callback after click
			}).showToast();
	}		

		else if (response?.data) {
			Object.keys(response.data).map((key: any, index) => {
				Toastify({
					text: response.data[key][0] + ' - ' + key,
					duration: 3000,
					close: true,
					gravity: "top", // `top` or `bottom`
					position: "right", // `left`, `center` or `right`
					stopOnFocus: true, // Prevents dismissing of toast on hover
					style: {
						background: "linear-gradient(to right, var(--v-error-base), var(--v-error-darken1))",
					},
					onClick: function(){} // Callback after click
				}).showToast();
			});
		}



		if (response && response.status === 401) {
			let accessToken = '';

			await getSilentToken();

			config.headers.Authorization =
			"Bearer " +
			JSON.parse(sessionStorage.getItem("AuthDetails") || "").accessToken;

			Toastify({
				text: "Token Expired, Please Reauthenticate",
				duration: 3000,
				close: true,
				gravity: "top", // `top` or `bottom`
				position: "right", // `left`, `center` or `right`
				stopOnFocus: true, // Prevents dismissing of toast on hover
				style: {
					background: "linear-gradient(to right, var(--v-error-base), var(--v-error-darken1))",
				},
				onClick: function(){} // Callback after click
			}).showToast();

			return new Promise(resolve => {
				resolve(instance(originalRequest));
			});
								
		}
		

		if (response && response.status === 403) {
			Toastify({
				text: "You do not have access to perform this action - " + config.url,
				duration: 3000,
				close: true,
				gravity: "top", // `top` or `bottom`
				position: "right", // `left`, `center` or `right`
				stopOnFocus: true, // Prevents dismissing of toast on hover
				style: {
					background: "linear-gradient(to right, var(--v-error-base), var(--v-error-darken1))",
				},
				onClick: function(){} // Callback after click
			}).showToast();
		}

		return Promise.reject(error);
	}
)


export class HttpService {
	isLoading$ = new BehaviorSubject(false);

  public Get<T>(url: string, baseUrlOverwrite?: string): Promise<T> {
		this.isLoading$.next(true);	

		if (baseUrlOverwrite) {
			instance.defaults.baseURL = baseUrlOverwrite;
		}

    return new Promise((resolve, reject) => {
      instance.get(`${url}`).then(
        (response: AxiosResponse<T>) => {
          resolve(response.data);
					this.isLoading$.next(false);				
        },
        (err: any) => {
          reject(err);
					this.isLoading$.next(false);				
        }
      );
    });
  }

	public GetAxios<T>(url: string, baseUrlOverwrite?: string, options: any = {}): Promise<AxiosResponse<T>> {
		this.isLoading$.next(true);	

		if (baseUrlOverwrite) {
			instance.defaults.baseURL = baseUrlOverwrite;
		}

    return new Promise((resolve, reject) => {
      instance.get(`${url}`, options).then(
        (response: AxiosResponse<T>) => {
          resolve(response);
					this.isLoading$.next(false);				
        },
        (err: any) => {
          reject(err);
					this.isLoading$.next(false);				
        }
      );
    });
  }

  public Post<T>(url: string, data: T, baseUrlOverwrite?: string): Promise<T> {
		this.isLoading$.next(true);	

		if (baseUrlOverwrite) {
			instance.defaults.baseURL = baseUrlOverwrite;
		}

    return new Promise((resolve, reject) => {
      instance.post(`${url}`, data).then(
        (response: AxiosResponse<T>) => {
          resolve(response.data);
					this.isLoading$.next(false);				
        },
        (err: any) => {
          reject(err);
					this.isLoading$.next(false);				
        }
      );
    });
  }

	public Patch<T>(url: string, data: T, baseUrlOverwrite?: string): Promise<T> {
		this.isLoading$.next(true);	

		if (baseUrlOverwrite) {
			instance.defaults.baseURL = baseUrlOverwrite;
		}

    return new Promise((resolve, reject) => {
      instance.patch(`${url}`, data).then(
        (response: AxiosResponse<T>) => {
          resolve(response.data);
					this.isLoading$.next(false);				
        },
        (err: any) => {
          reject(err);
					this.isLoading$.next(false);				
        }
      );
    });
  }

  public Put<T, T2 = T>(url: string, data: T, baseUrlOverwrite?: string): Promise<T2> {
		this.isLoading$.next(true);				

		if (baseUrlOverwrite) {
			instance.defaults.baseURL = baseUrlOverwrite;
		}

    return new Promise((resolve, reject) => {
      instance.put(`${url}`, data).then(
        (response: AxiosResponse<T2>) => {
          resolve(response.data);
					this.isLoading$.next(false);				
        },
        (err: any) => {
          reject(err);
					this.isLoading$.next(false);				
        }
      );
    });
  }

  public Delete<T>(url: string, baseUrlOverwrite?: string): Promise<T> {
		this.isLoading$.next(true);				

		if (baseUrlOverwrite) {
			instance.defaults.baseURL = baseUrlOverwrite;
		}
		
    return new Promise((resolve, reject) => {
      instance.delete(`${url}`).then(
        (response: AxiosResponse<T>) => {
          resolve(response.data);
					this.isLoading$.next(false);				
        },
        (err: any) => {
          reject(err);
					this.isLoading$.next(false);				
        }
      );
    });
  }
}

